.container {
    position: relative;
}

.inputField {
    cursor: pointer;
}

.optionContainer {
    position: absolute;
    width: 244px;
    margin: 0;
    padding: 0;
    top: calc(100% - 15px);
    left: 0;
    max-height: 150px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: white;
    box-shadow: 0 0px 1px 0px black;
    overflow-y: auto;
    z-index: 1;
}

.option {
    cursor: pointer;
    list-style: none;
    padding: 5px;
    transition: all .2s;
}

.option:hover {
    background-color: rgb(240, 240, 240);
}