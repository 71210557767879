.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 20px 50px;
}

.formTextarea {
    margin: 12px 0;
    font-family: inherit;
    font-size: 16px;
    padding: 8px;
    width: 600px;
    min-height: 230px;
    border: 0;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    resize: vertical;
    transition: box-shadow .2s;
}

.formTextarea:focus {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}

.formTextarea {
    outline: none;
}

.formButton {
    margin: 20px 0;
    padding: 10px 12px;
    font-size: 18px;
    color: white;
    background-color: rgb(38, 159, 168);
    border-radius: 4px;
}

.formButton:hover {
    background-color: rgb(35, 189, 200);
}

.errorMessage {
    font-size: 15px;
    color: rgb(145, 24, 24);
}

.phoneNumberText {
    text-align: center;
    margin: 10px 10px 30px;
    font-size: 20px;
    font-weight: 600;
}

@media screen and (max-width: 650px) {
    .formTextarea {
        width: 90%;
    }
}