.consentCookies {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    backdrop-filter: brightness(80%) blur(2px);
}

.wrapper {
    width: 50%;
    padding: 15px 0 5px;
    overflow-y: scroll;
    border-radius: 0px;
    background-color: white;
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.4);
}

.header {
    margin: 30px 20px 20px;
    font-weight: 500;
    font-size: 30px;
}

.text {
    margin: 20px;
    font-weight: 500;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
    margin: 20px 0 30px 20px;
}

.buttonWrapper {
    display: flex;
    justify-content: space-between;
    width: 410px;
    margin: 0 20px;
}

.button {
    width: 130px;
    height: 40px;
    cursor: pointer;
    font-family: Quicksand;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 18px;
    color: white;
    border: 0;
}

.button:focus {
    outline: none;
}

.acceptButton {
    background-color: rgb(83, 187, 63);
}

.partlyAcceptButton {
    background-color: rgb(108, 83, 250);
}

.refuseButton {
    background-color: rgb(70, 70, 70);
}

@media screen and (min-width: 450px) and (max-width: 900px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        width: 65%;
        max-height: 70%;
    }
    .wrapper::-webkit-scrollbar {
        width: 5px;
        height: 0px;
    }
    .text {
        font-size: 17px;
        margin: 10px 20px;
    }
    .buttonWrapper {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 15px;
        max-width: 100%;
    }
    .checkboxContainer {
        align-self: flex-start;
        margin: 0 20px;
    }
    .header {
        font-size: 24.5px;
        margin: 10px 20px;
    }
    .button {
        margin: 5px;
        width: 180px;
    }
}

@media screen and (max-width: 450px) {
    .header {
        font-size: 24px;
        margin: 5px 20px 10px;
    }
    .wrapper {
        width: 95%;
        max-height: 90%;
    }
    .buttonWrapper {
        align-items: center;
        flex-direction: column;
        margin: 10px;
        width: auto;
    }
    .button {
        margin: 2px 0;
        width: 100%;
        border-radius: 0;
    }
    .text {
        margin: 5px 20px;
        font-size: 15px;
    }
    .checkboxContainer {
        margin: 0 20px 5px;
    }
}