.landingImageContainer {
    height: 100vh;
    background-image: url('./Assets/man_smiling.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.landingImage {
    position: relative;
    height: 100%;
    width: 100%;
}

.textContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
    top: 45%;
    right: 0;
    transform: translate(-10%, -50%);
}

.textDescription {
    margin: 10px 0;
    font-size: 21px;
}

.readmoreLink {
    font-size: 20px;
    color: black;
    text-decoration: none;
}

.linkContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .55);
}

.tileHeader {
    margin: 20px 0;
    color: rgb(24, 199, 211);
    text-decoration: none;
    transition: color .1s;
}

.tileHeader:hover {
    color: rgb(3, 231, 248);
}

.imgTextPairContainer {
    display: flex;
}

.imgTextPairImg {
    width: 50%;
}

.imgTextPairList {
    width: 50%;
    padding: 10px;
    font-size: 2.3vh;
    line-height: 1.5;
    color: white;
    background-color: rgb(129, 191, 191);
}

.imageContainer {
    position: relative;
}

.imageText {
    font-size: 25px;
    text-shadow: 1px 1px 5px rgba(250, 250, 250, .4);
}

@media screen and (max-width: 1050px) {
    .imgTextPairContainer {
        flex-direction: column;
    }
    .imgTextPairImg {
        width: 100%;
    }
    .imgTextPairList {
        width: auto;
        padding: 10px 10px 20px;
    }
}

@media screen and (max-width: 800px) {
    .textContainer {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .imageText {
        font-size: 22px;
    }
}

@media screen and (max-width: 1100px) {
    .landingImage {
        backdrop-filter: brightness(.6);
    }
    .textDescription {
        color: white;
    }
    .textContainer {
        width: 80%;
        top: 50%;
    }
}