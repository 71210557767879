.container {
    margin: 50px 20px 30px;
    line-height: 1.6;
}

.list {
    margin: 20px 20px 35px;
    padding: 0 20px;
    position: relative;
    max-width: 1000px;
    margin: 0 20px 10px;
}

.listItem {
    list-style-type: decimal;;
}

.openInfoTextButton {
    width: 40px;
    height: 40px;
    margin: 0 30px 30px 30px;
    font-size: 24px;
    border-radius: 50%;
    padding: 0px;
    background-color: rgb(234, 234, 234);
}

.openInfoTextButton:hover {
    background-color: rgb(245, 245, 245);
}

.textFade:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(rgb(0, 0, 0, 0), rgb(255, 255, 255, 1));
}