.aggreementContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 45px 0 5px;
}

.aggreementDescription {
    position: relative;
    max-width: 1000px;
    margin: 0 20px 5px;
    font-size: 15px;
}

.descriptionTextFade:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(rgb(0, 0, 0, 0), rgb(255, 255, 255, 1));
}

.aggreementButton {
    margin: 5px 20px 20px;
    padding: 7px 10px;
    font-size: 16px;
    border: 1px solid rgb(38, 159, 168);
    border-radius: 4px;
    background-color: transparent;
    transition: all .3s;
}

.openInfoTextButton {
    width: 40px;
    height: 40px;
    margin-bottom: 30px;
    font-size: 24px;
    border-radius: 50%;
    padding: 0px;
    background-color: rgb(234, 234, 234);
}

.openInfoTextButton:hover {
    background-color: rgb(245, 245, 245);
}