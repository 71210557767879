.container {
    margin: 80px 20px 10px;
}

.headerColumn {
    border: 1px solid black;
    font-weight: 1000;
    padding: 6px 9px;
}

.tableContainer {
    overflow-x: auto;
}

.table {
    margin: 0 auto 55px;
    border-collapse: collapse;
    border: 1px solid black;
}

.row {
    border: 1px solid black;
    font-weight: 500;
    padding: 6px 9px;
    hyphens: auto;
}

.column {
    border: 1px solid black;
    font-weight: 500;
    padding: 6px 9px;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.saveButton {
    margin-top: 20px;
    padding: 11px;
    width: 130px;
    border: 0;
    font-size: 19px;
    font-family: 'Quicksand';
    color: white;
    border-radius: 8px;
    background-color: rgb(108, 83, 250);
    transition: all .3s;
    cursor: pointer;
}

.header {
    text-align: center;
    font-size: 30px;
}

@media screen and (max-width: 650px) {
    .headerColumn {
        font-size: 13px;
    }
    .row {
        font-size: 13px;
    }
    .column {
        font-size: 13px;
    }
}

@media screen and (max-width: 550px) {
    .headerColumn {
        padding: 3px 1px;
    }
    .row {
        padding: 3px 1px;
    }
    .column {
        padding: 3px 1px;
    }
}

@media screen and (max-width: 420px) {
    .cookieWrapper {
        margin: 20px 5px;
    }
}