.checkboxLabel {
    display: flex;
    align-items: center;
}

.checkbox {
    width: 17px;
    height: 17px;
    margin: 6px;
}

@media screen and (max-width: 450px) {
    .checkboxLabel {
        font-size: 15px;
    }
}