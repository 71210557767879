.menuContainer {
    width: 240px;
    position: relative;
}

.labelButton {
    width: 240px;
    margin: 10px 0;
    padding: 2px;
    text-align: start;
    font-size: 16px;
    border-radius: 0;
    border-bottom: 1px solid black;
    color: gray;
    background-color: rgb(255, 255, 255, 1);
    word-break: break-all;
    transition: all 0s;
}

.checkboxOptionContainer {
    display: flex;
    align-items: center;
}

.optionList {
    position: absolute;
    top: 100%;
    margin: 0;
    padding: 0;
    width: 240px;
    max-height: 140px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .2);
    list-style-type: none;
    z-index: 1;
}

.option {
    margin: 2px 0;
    padding: 5px 9px;
    font-size: 16px;
    word-break: break-all;
    cursor: pointer;
    transition: background-color .1s;
}

.option:hover {
    background-color: rgb(255, 255, 255, 1);
}

.multipleValueOption:hover {
    cursor: default;
    background: transparent;
}

.checkBox {
    appearance: none;
    -webkit-appearance: none;
    height: 25px;
    width: 25px;
    margin: 6px 0 0 5px;
    cursor: pointer;
    position: relative;
    outline: none;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 2px;
}

.checkBox:checked:before {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    content: '✓';
    text-align: center;
    outline: none;
}

.checkBox:active {
    outline: none;
}