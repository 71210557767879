.footer {
    background-color: rgb(243, 243, 243);
    padding-bottom: 5px;
    text-align: center;
    margin-top: auto;
}

.contentWrapper {
    display: flex;
    justify-content: center;
}

.toTop {
    cursor: pointer;
    background-color: rgb(230, 230, 230);
    padding: 10px;
    transition: background-color .2s;
}

.toTop:hover {
    background-color: rgb(220, 220, 220);
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(75px, auto));
    margin-top: 65px;
    margin-bottom: 50px;
    width: 85%;
}

.logo {
    max-width: 80px;
    cursor: pointer;
}

.item {
    margin: 30px 0;
}

.text {
    font-size: 14px;
    color: black;
    text-decoration: none;
    transition: font-size .2s;
}

.text:hover {
    font-size: 14.5px;
}

.brandRow {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socialIcons {
    display: flex;
    justify-content: space-around;
    width: 50%;
    background-color: rgb(235, 235, 235);
    border-radius: 22px;
    padding: 5px 12px;
}

.icon {
    filter: drop-shadow(1px 1px 2px rgb(0, 0, 0, .25));
    font-size: 31px;
    cursor: pointer;
}

.youtubeIcon {
    color: rgb(255, 0, 0);
}

.facebookIcon {
    color: rgb(66 103 178);
}

.instagramIcon {
    color: white;
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    width: 31px;
    height: 31px;
    border-radius: 7px;
}

.twitterIcon {
    color: rgb(29, 161, 242);
}

.pseudoElement {
    min-width: 5px;
}

.link {
    text-decoration: none;
    color: black;
    transition: all .1s;
}

.link:hover {
    color: rgb(11, 125, 133);
}

.copyrightText {
    font-size: 14px;
}

.linkContainer {
    margin: 20px;
}

@media screen and (min-width: 500px) and (max-width: 620px) {
    .socialIcons {
        background-color: transparent;
    }
}

@media screen and (max-width: 900px) {
    .grid {
        grid-template-columns: repeat(auto-fit, minmax(130px, auto));
    }
    .seperator {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, auto));
        margin: 20px 0;
    }
    .brandRow {
        order: 1;
    }
    .logo {
        margin-top: 40px;
    }
}