.benefitsContainer {
    display: flex;
    margin-bottom: 20px;
}

.cardContainer {
    flex: 1;
    text-align: center;
    margin: 15px;
}

.header {
    color: rgb(24, 199, 211);
    margin-bottom: 0;
}

.description {
    margin-top: 5px;
}

@media screen and (max-width: 800px) {
    .benefitsContainer {
        flex-direction: column;
        align-items: center;
    }
    .cardContainer {
        max-width: 450px;
    }
}