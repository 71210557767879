.landingImageContainer {
    height: 100vh;
    background-image: url('../../Assets/woman_men_smiling.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.landingImage {
    position: relative;
    height: 100%;
    width: 100%;
}

.logoContainer {
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(-50%, -50%);
}

.logoContent {
    width: 150px;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
}

.logo {
    position: absolute;
    left: 0;
    width: 150px;
    height: 150px;
}

.logoDescription {
    position: absolute;
    top: 32%;
    left: 80%;
    font-size: 23px;
    color: white;
    white-space: nowrap;
    text-align: center;
}

.phoneNumber {
    font-size: 21px;
}

.tileContainer {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .55);
}

.tile {
    flex: 1;
    text-align: center;
    margin: 20px 0;
    color: white;
    font-size: 18px;
}

.tileHeader {
    color: rgb(24, 199, 211);
    text-decoration: none;
    transition: color .1s;
}

.tileHeader:hover {
    color: rgb(3, 231, 248);
}

@media screen and (max-width: 500px) {
    .logoDescription {
        top: 100%;
        left: -35%;
    }
}

@media screen and (max-width: 800px) {
    .logoContainer {
        flex-direction: column;
    }
    .tileContainer {
        flex-direction: column;
    }
    .tile {
        margin: 15px;
    }
}

@media screen and (max-width: 1100px) {
    .landingImage {
        backdrop-filter: brightness(.7);
    }
    .logoContainer {
        top: 40%;
        left: 50%;
    }
}