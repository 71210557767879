.image {
    position: relative;
    height: 500px;
    background-image: url('../../Assets/couch.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
}

.header {
    text-align: center;
    margin: 50px 0 30px;
}