.container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.mainMenuContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.88);
}

.dropdownMenuContainer {
    background-color: rgba(255, 255, 255, 0.88);
    backdrop-filter: blur(3px);
    padding: 10px 140px 15px 25px;
}

.openMenuButton {
    width: 50px;
    border: 0;
    font-size: 20px;
    color: black;
    background-color: transparent;
    cursor: pointer;
    transition: all .2s;
}

.openMenuButton:hover {
    transform: scale(1.14);
}