.container {
    display: flex;
    max-width: 600px;
    margin: 0 15px 5px;
    text-align: left;
}

.invisibleText {
    height: 0px;
    line-height: 0;
    opacity: 0;
    transition: all .18s;
}

.visibleText {
    height: 100%;
    line-height: 1.3;
    opacity: 1;
}

.button {
    position: relative;
    min-width: 25px;
    padding: 0;
    border: 0;
    font-size: 21px;
    background: transparent;
    cursor: pointer;
}

.button:focus {
    outline: none;
}

.buttonText {
    position: absolute;
    top: 0;
    left: 6px;
}