.container {
    display: flex;
    justify-content: center;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 30px 10px;
}

.submitButton {
    margin: 20px;
    padding: 10px 12px;
    font-size: 18px;
    color: white;
    background-color: rgb(38, 159, 168);
    border-radius: 4px;
}

.submitButton:hover {
    background-color: rgb(35, 189, 200);
}

.errorMessage {
    margin: 0 20px;
    font-size: 15px;
    color: rgb(145, 24, 24);
}