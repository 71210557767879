.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.recruitementChannels {
    width: 100%;
    color: white;
    background-color: black;
}

.modernCommunication {
    margin: 50px 0;
}

.header {
    margin: 20px 10px;
}

.header2 {
    margin: 100px 10px;
}

.image {
    width: 70%;
}

.description {
    margin: 50px 10px 100px;
}