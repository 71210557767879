.container {
    display: flex;
    padding: 8px 0 2px;
    text-decoration: none;
    cursor: pointer;
}

.container:hover .icon {
    transform: scale(1.2);
}

.container:active>* {
    color: rgb(89, 89, 89);
}

.icon {
    font-size: 16px;
    color: black;
    transition: all .18s;
}

.link {
    margin: 3px 7px;
    font-size: 14px;
    color: black;
}