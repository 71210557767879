@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');
html {
    min-height: 100vh;
}

body {
    margin: 0;
    font-family: Quicksand;
    font-size: 18px;
    min-height: 100vh;
}

h1 {
    font-weight: 100;
}

h2 {
    font-weight: 100;
    margin: 15px 0;
}

h3 {
    margin: 10px;
}

h4 {
    margin: 7px;
}

button {
    border: 0;
    cursor: pointer;
    font-family: 'quicksand';
    transition: all .2s;
}

button:focus {
    outline: none;
}

input {
    display: block;
    margin: 15px 0;
    padding: 3px 2px;
    font-size: 17px;
    font-family: 'Quicksand';
    border: 0;
    border-bottom: 1px solid black;
    outline: none;
    width: 240px;
}

textarea {
    width: 550px;
    height: 70px;
    margin: 10px;
    padding: 5px;
    font-size: 17px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(100, 100, 100, .3);
    -moz-box-shadow: 0 0 5px 0 rgba(100, 100, 100, .3);
    -webkit-box-shadow: 0 0 5px 0 rgba(100, 100, 100, .3);
    transition: all .15s;
    font-family: 'quicksand';
    outline: none;
}

textarea:focus {
    box-shadow: 0 0 5px 0 rgba(0, 158, 169, .4);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 158, 169, .4);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 158, 169, .4);
}

ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

input[type='date']:not(:focus):not(.has-value):before {
    color: rgb(110, 110, 110);
    content: attr(placeholder);
    margin-right: 5px;
}

input[type='time']:not(:focus):not(.has-value):before {
    color: rgb(110, 110, 110);
    content: attr(placeholder);
    margin-right: 5px;
}

@media screen and (max-width: 700px) {
    textarea {
        width: 90%;
    }
}